<template>
    <div id="print-page" class="mx-auto my-0 py-0" v-if="reportData">
        <h2 class="mt-4 ml-8">{{ reportData.disabledAt ? $t('destruction_report') : $t('installation_report') }}</h2>
        <h1 class="ml-8">{{ $t('Project')}} {{ reportData.project.name }} - {{ $t('Camera')}} {{ reportData.camNo }}</h1>
        <v-row class="mt-6 d-flex justify-space-around" style="width: 100%;">
            <v-col class="report-card">
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>{{ $t('Client') }}</td>
                                <td>{{ reportData.project.client ? reportData.project.client.name : '' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('start') }}</td>
                                <td>{{ reportData.enabledAt ? date(reportData.enabledAt) : '---' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('end') }}</td>
                                <td>{{ reportData.disabledAt ? date(reportData.disabledAt) : '---' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('run_start') }}</td>
                                <td>{{ reportData.runtimeStart ? date(reportData.runtimeStart) : '---' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('run_end') }}</td>
                                <td>{{ reportData.runtimeEnd ? date(reportData.runtimeEnd) : '---' }}</td>
                            </tr>
                            <tr>
                                <td>Siteview ID</td>
                                <td><strong>{{ reportData.siteviewId }}</strong></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>

            <v-col class="report-card d-flex justify-end" >
                <v-img id="display-image" :src="imageSrc"
                contain
                style="float:right;flex-grow: unset;"
                height="250"
                lazy-src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"></v-img>
                <img :src="imageSrc"
                    style="float:right;"
                    id="print-image"
                    height="250" />
            </v-col>
        </v-row>

        <v-row class="my-4 d-flex justify-space-around" v-for="index in rowCount" :key="index" :id="index" style="width: 100%;">
            <v-col class="report-card" v-for="(reportItem, idx) in report.slice((index-1)*2, ((index-1)*2)+2)"
                :key="idx">
                <h2 class="mb-4" v-if="reportItem">{{ $t(capFirst(reportItem.name)) }}</h2>
                <v-simple-table v-if="reportItem">
                    <template v-slot:default>
                        <tbody id="body-cards">
                            <tr
                                v-for="(item, key) in reportItem.data"
                                :key="key">
                                <td>{{ $t(item[0]).toUpperCase() }}</td>
                                <td>{{ item[1] }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
</template>


<script>
import { mapActions } from 'vuex'
import { rest } from '../_helpers'

const moment = require('moment-timezone')

export default {
    props: {
        systemId: Number,
        image: String,
        data: {
            default() {
                return null
            },
            type: Object,
        },
    },

    data() {
        return {
            reportData: this.data,
            imageSrc: this.image,
        }
    },

    computed: {
        rowCount() {
            return this.report.length / 2
        },

        report() {
            const report = []
            const dataTypes = ['computer', 'camera', 'housing', 'lens', 'control', 'router', 'sim1', 'sim2', 'simSwitch']
            dataTypes.forEach(type => {
                const key = `${type}Data`

                if (this.reportData
                    && Object.prototype.hasOwnProperty.call(this.reportData, key)
                    && this.reportData[key]) {
                    const data = this.reportData[key]
                    let fields = []
                    const defaults = [
                        ['Manufacturer', data.make],
                        ['Model', data.model],
                        ['Serialnumber', data.serialNumber],
                    ]

                    if (type === 'computer') {
                        fields = [['Name', (data.computer ? data.computer.name : '---')], ...defaults]
                    } else if (type.startsWith('sim')) {
                        fields = [
                            ['Company', data.company],
                            ['Number', data.number],
                            ['Ind', data.Ind],
                        ]
                    } else {
                        fields = defaults
                    }

                    report.push({ name: type, data: fields })
                }
            })

            if (report.length % 2 !== 0) {
                report.push(null)
            }

            return report
        },
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
        }),

        date(date) {
            return moment(date).format('YYYY-MM-DD')
        },

        getData() {
            return rest.getRow('system', this.systemId)
                .then(item => {
                    if (item) {
                        this.reportData = item
                    }
                })
        },

        capFirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
    },

    mounted() {
        this.showSpinner(false)
    },

    created() {
        this.showSpinner(true)
    },

    watch: {
        data(val) {
            this.reportData = val
        },

        image(val) {
            this.imageSrc = val
        },
    },
}
</script>


<style>
    #print-page {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 2em
    }
    #print-image {
        display: none;
    }
    #report {
        height: 100%;
    }
    .report-card {
        max-width: 500px;
    }

    @media print {
        body {
            width: 21cm;
            padding-left: 2cm;
            padding-right: 2cm;
            font-size: 16px;
            height: unset;
            overflow-y: auto;
        }
        #main-content {
            padding: 0 !important;
        }
        #print-page {
            font-size: 12px;
            padding-right: 0;
        }
        .no-print {
            display: none;
        }
        #print-image {
            display: block;
            height: 150px;
        }
        #display-image {
            display: none;
        }
        .report-card:first-child {
            padding-right: 1cm;
        }
        #body-cards td {
            height: 32px !important;
        }
    }
</style>
